<template>
  <div class="app-EditInformation">
    <div class="CreditCardInformation">
      <h1
          style="
                    font-size: 30px;
                    font-weight: 500;
                    margin: 0px 0px 10px 0px;
                  "
      >
        {{ this.$t('register.us.legend.creditCard') }}
      </h1>
      <hr style="opacity: 0.4"/>

      <div style="text-align: left">
        <el-form label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item class="turn-white" prop="cardNo"  :label="$t('title.cardNo')">
                <el-input style="width: 300px" size="mini" v-model="listQuery1.cardNo"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="turn-white"  prop="priority" :label="$t('sysDataLog.changeType')">
                <!--                    {{$lt('credit_card_type',listQuery.priority)}}-->
                <el-select
                    size="mini"
                    v-model="listQuery1.priority"
                    clearable
                >
                  <el-option
                      v-for="item in creditcardtype"
                      :key="item.code"
                      :label="$t(item.title)"
                      :value="Number(item.code)"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


        </el-form>
        <el-button :loading="btnloading" type="primary" @click="getlist()" >{{ this.$t('pl.plate.search') }} </el-button>
        <el-button :loading="btnloading" type="primary" @click="add()" >{{ this.$t('sysDataLog.insert') }} </el-button>
      </div>
      <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;">
        <!-- 操作 -->
        <el-table-column :label="$t('sysOperationLog.moduleName')" align="center">
          <template slot-scope="{row}">
            <!--          查看-->
            <el-button
                :loading="btnloading"
                :title="$t('button.view')"
                type="primary"
                icon="el-icon-edit"
                circle
                size="small"
                @click="edit(row)"/>
            <!--          删除-->
            <el-button :loading="btnloading" :title="$t('button.delete')" icon="el-icon-delete" size="small" type="danger" circle @click="deleteBtn(row)" />
          </template>
        </el-table-column>
        <!--   姓名   -->
        <el-table-column :label="$t('Cardholder_Name')" prop="cardName" align="center" />
        <!--  卡号    -->
        <el-table-column :label="$t('title.cardNo')" prop="cardNo" align="center" />
        <!--    手机号  -->
        <el-table-column :label="$t('Cardholder_Cellphone')" prop="cardMobile" align="center" />
        <!--     邮箱 -->
        <el-table-column :label="$t('Cardholder_Email')" prop="cardEmail" align="center" />
        <!--   有效期  -->
        <el-table-column :label="$t('Validity_Date')" prop="periodOfValidity" align="center" />
        <!--   状态   -->
        <el-table-column :label="$t('sysDataLog.changeType')" prop="priority" align="center">
          <template slot-scope="{row}">{{$lt(initDict.creditcardtype,row.priority)}}
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          v-loading="listLoading"
          :visible.sync="dcNoBuledialong"
          width="40%"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <div style="display: flex">
                <el-col :span="24" style="margin-left: 20px">
                  <div class="grid-content bg-purple-dark" style="text-align: left">

                    <el-form
                        :rules="rules"
                        ref="ruleForm"
                        :model="listQuery"
                        class="demo-ruleForm"
                        label-width="auto"
                    >
                      <!--    卡号              -->
                      <el-form-item class="turn-white" prop="cardNo"  :label="$t('title.cardNo')">
                        <el-input v-model="listQuery.cardNo"></el-input>
                      </el-form-item>
                      <!--       持卡人姓名           -->
                      <el-form-item class="turn-white"  :label="$t('Cardholder_Name')">
                        <el-input  v-model="listQuery.cardName"></el-input>
                      </el-form-item>
                      <!--          持卡人手机号        -->
                      <el-form-item class="turn-white" :label="$t('Cardholder_Cellphone')">
                        <el-input   v-model="listQuery.cardMobile"></el-input>
                      </el-form-item>
                      <!--        持卡人邮箱          -->
                      <el-form-item class="turn-white"  :label="$t('Cardholder_Email')">
                        <el-input  v-model="listQuery.cardEmail"></el-input>
                      </el-form-item>
                      <!--               有效期   -->
                      <!--      有效期-->
                      <el-form-item :label="$t('Validity_Date')+(':')"
                                    >
<!--                        <el-select v-model="listQuery.validityMonth">-->
<!--                          <el-option v-for="item in MonthList" :key="item" :label="item" :value="item"/>-->
<!--                        </el-select>-->
<!--                        &nbsp;-&nbsp;-->
<!--                        <el-select v-model="listQuery.validityYear">-->
<!--                          <el-option v-for="item in YearList" :key="item" :label="item" :value="item"/>-->
<!--                        </el-select>-->
                        <el-date-picker
                            value-format="MM-yyyy"
                            v-model="listQuery.periodOfValidity"
                            type="month">
                        </el-date-picker>
                      </el-form-item>
                      <!--                  -->
                      <el-form-item class="turn-white"  prop="priority" :label="$t('mi.input.select')">
                        <!--                    {{$lt('credit_card_type',listQuery.priority)}}-->
                        <el-select

                            v-model="listQuery.priority"
                            clearable
                        >
                          <el-option
                              v-for="item in creditcardtype"
                              :key="item.code"
                              :label="$t(item.title)"
                              :value="Number(item.code)"
                          />
                        </el-select>
                      </el-form-item>
                    </el-form>

                  </div>
                </el-col>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 尾部按钮 -->
        <span slot="footer" class="dialog-footer">
                    <el-button @click="dcNoBuledialong = false" >{{ this.$t('operation.button.cancel') }} </el-button>
                     <el-button style="margin-left: 10px" :loading="btnloading" type="primary" @click="save()">
                          {{ this.$t('btn.continue') }}
                        </el-button>
                  </span>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import {creditCardSave, creditCardShow} from "@/api/userInfo/userInfo";
import {creditCardRemove} from "../../../../web-manager/src/api/ic/icOnlineNews";

export default {
  name: "CreditCardInformation",
  data(){
    return{
      MonthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      YearList: [],
      listQuery:{
        validityMonth:'',
        validityYear:''
      },
      listQuery1:{},
      btnloading:false,
      listLoading:false,
      dcNoBuledialong:false,
      list:[],
      disabled:true,
      priority:[],
      rules: {
        priority: [
          {required: true, message: this.$t('mi.input.select'), trigger: 'change'}
        ],
        cardNo: [
          {required: true, message: this.$t('po.setInfo'), trigger: 'blue'}
        ],
      },
      initDict: {
        creditcardtype: 'credit_card_type',
      }
    }
  },
  computed:{
    creditcardtype(){
      return this.$ll(this.initDict.creditcardtype)
    }
  },
  created() {
    this.getlist()
    for (let i = 2023; i < 2101; i++) {
      this.YearList.push(i)
    }
  },
  methods:{
    getlist(){
      this.btnloading = true
      this.listLoading = true
      creditCardShow({...this.listQuery1}).then(res=>{
        this.list = res.data
        this.btnloading = false
        this.listLoading = false
      }).catch(err=>{
        console.warn(err)
        this.btnloading = false
        this.listLoading = false
      })
    },
    add(){
      this.listQuery = {}
      this.dcNoBuledialong = true
    },
    edit(row){
      this.listQuery = row
      this.dcNoBuledialong = true
    },
    deleteBtn(row) {
      this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('operation.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.btnloading = true
        creditCardRemove({ 'id': row.id }).then(() => {
          this.$message.success(this.$t('bdSendRegister.operaterSuccess'))
          this.btnloading = false
          this.getlist()
        }).catch(() => {
          this.$message.error(this.$t('bdSendRegister.operaterFail'))
          this.btnloading = false
        })
      })
    },
    save(){
      if(!this.listQuery.cardNo){
        return  this.$message.error(this.$t('po.setInfo')+this.$t('title.cardNo'))
      }
      if(!this.listQuery.priority){
        return  this.$message.error(this.$t('mi.input.select')+this.$t('sysDataLog.changeType '))
      }
      this.btnloading = true
      this.listLoading = true
      // this.listQuery.periodOfValidity = this.listQuery?.validityMonth + '-' + this.listQuery?.validityYear
      creditCardSave({...this.listQuery}).then(res=>{
        console.log(res)
        this.$notify({
          title: '',
          message: this.$t('success.operation'),
          type: 'success'
        })
        this.getlist()
        this.btnloading = false
        this.dcNoBuledialong = false
        this.listLoading = false
      }).catch(err=>{
        console.error(err)
        this.btnloading = false
        this.listLoading = false
      })


    }
  }
}
</script>

<style scoped lang="scss">
.turn-white{
::v-deep .el-input__inner{
  background-color: white;
  color: black;
}
::v-deep .el-textarea__inner{
  background-color: white;
  color: black;
}
}
</style>
